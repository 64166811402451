var $ = jQuery;
$(document).ready(function(){
	//prepend html para los precios del filtro slide 
	//var current_currency = $('.wmc-current-currency').text();
	//$('.slider.price .slide').prepend('<div class="filter-left"><span class="min-price">2000</span> <span class="currency_symbol">'+current_currency+'</span></div><div class="filter-right"><span class="max-price">2000</span> <span class="currency_symbol">'+current_currency+'</span></div>');
	
	
});
/*
$(document).on('change',".slider.price .left input,.slider.price .right input",function(){
	alert("cambio");
		var new_min_value = $(".slider.price .left input").val();
		var new_max_value = $(".slider.price .right input").val();
		
		$(".slider.price .filter-right .max-price").empty();
		$(".slider.price .filter-right .max-price").append(new_max_value);
		
});

$( document ).ajaxComplete(function( event, request, settings ) {

 var new_min_value = $(".slider.price .left input").val();
		var new_max_value = $(".slider.price .right input").val();
		

});*/